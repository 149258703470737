// This file is compiled inside Decidim core pack. Code can be added here and will be executed
// as part of that pack

// Load images
require.context("../../images", true);

$(document).ready(function () {

  $('#dropdown-menu-search-mobile').removeAttr('role');

  // Utility functions
  const showOrHideElement = (selector, shouldShow) => {
    $(selector).toggle(shouldShow);
  };

  // Function to toggle the maintenance cost field
  function toggleMaintenanceCostField() {
    const shouldShow =
      $(
        'input[name="project_questionnaire[maintaince_cost_exist]"]:checked',
      ).val() === "true";
    showOrHideElement(
      'label[for="project_questionnaire_maintaince_cost"]',
      shouldShow,
    );
  }

  function toggleUniversalDesign() {
    const shouldShow =
      $(
        'input[name="project_questionnaire[universal_design]"]:checked',
      ).val() === "false";
    showOrHideElement(
      'label[for="project_questionnaire_universal_design_justification"]',
      shouldShow,
    );
  }

  // Function to toggle the others field
  function toggleOthersField() {
    const shouldShow = $("#project_questionnaire_potential_audience_others").is(
      ":checked",
    );
    showOrHideElement(
      'label[for="project_questionnaire_potential_audience_others_details"]',
      shouldShow,
    );
  }

  // Event bindings
  $('input[name="project_questionnaire[maintaince_cost_exist]"]').change(
    toggleMaintenanceCostField,
  );
  $('input[name="project_questionnaire[universal_design]"]').change(
    toggleUniversalDesign,
  );
  $("#project_questionnaire_potential_audience_others").change(
    toggleOthersField,
  );

  // Initial state toggle
  toggleMaintenanceCostField();
  toggleOthersField();
  toggleUniversalDesign();

  // Function to remove nested fields
  $(document).on("click", ".remove_fields", function (event) {
    event.preventDefault();
    $(this).closest("#nested-fields").remove();
    $(".add_fields").show();

    const currentFieldsCount = $("div.nested").length;
    let addFldBtn = document.querySelectorAll('.add_fields');

    if (addFldBtn.length > 0) {
      addFldBtn[addFldBtn.length - 1].id = `add_field_${currentFieldsCount + 1}`;
    }

    let authorTitle = document.querySelectorAll('.co_author_field');

    if (authorTitle.length > 0) {
      for (let i = 0; i < authorTitle.length; i++) {
        let j = i + 1;
        authorTitle[i].innerHTML = j;
      }
    }
  });

  // Function to add nested fields
  $(document).on("click", ".add_fields", function (event) {
    event.preventDefault();
    const maxFields = 6;
    const currentFieldsCount = $("div.nested").length;

    if (currentFieldsCount < maxFields) {
      const time = new Date().getTime();
      const link = $(this).data("association");
      const regexp = new RegExp(`new_${link}`, "g");
      const template = $(this).data("fields").replace(regexp, time);
      $(this).before(template);

      let authorTitle = document.querySelectorAll('.co_author_field');

      if (authorTitle.length > 0) {
        for (let i = 0; i < authorTitle.length; i++) {
          let j = i + 1;
          authorTitle[i].innerHTML = j;
        }
      }

      let peselBoxes = document.querySelectorAll('.user-pesel');

      if (peselBoxes.length > 0) {
        let peselBox = peselBoxes[peselBoxes.length - 1];
        peselBox.id = `pesel${currentFieldsCount}`;
        peselBox.addEventListener("input", function () {
          let pesel = peselBox.value.trim();
          validatePESELInProjectForm(pesel, currentFieldsCount)
        });
      }

      let parentsDiv = document.querySelectorAll('.parents-div');

      if (parentsDiv.length > 0) {
        parentsDiv[parentsDiv.length - 1].id = `parent${currentFieldsCount}`;
      }

      let addFldBtn = document.querySelectorAll('.add_fields');

      if (addFldBtn.length > 0) {
        addFldBtn[addFldBtn.length - 1].id = `add_field_${currentFieldsCount + 1}`;
      }

      if ($("div.nested").length === maxFields) {
        $(this).hide();
      }
    } else {
      alert("Osiągnąłeś limit w dodawaniu 5 współautorów.");
    }
  });

  // Function to validate cost input
  function validateCost(field) {
    const value = parseFloat(field.val());
    const min = parseFloat(field.attr("min"));
    const max = parseFloat(field.attr("max"));
    const errorField = field.siblings(".form-error");

    if (value == "") {
      errorField.show();
    } else if (value < min || value > max) {
      field.addClass("is-invalid-input");
      field.parent().addClass("is-invalid-label");
      const errorMessage = `nie mieści się w dopuszczalnych przedziałach ${min.toFixed(2)} zł do ${max.toFixed(2)} zł`;
      errorField.text(errorMessage).show();
    } else {
      errorField.text("").show();
    }
  }

  $(".modal_link").on("click", function (event) {
    $("#confirm-modal-questionnaire").attr("aria-hidden", "false");
    $("#project_questionnaire_status").val($(this).data("status"));
    $(".modal_content").html($(this).data("message"));
    $(".modal_button_text").html($(this).data("status-label"));
    console.log($(this).data("message"));
    console.log($(this).data("status"));
  });

  $(".close_modal").on("click", function (event) {
    event.preventDefault();
    $("#confirm-modal-questionnaire").attr("aria-hidden", "true");
  });

  // Attach validation on blur event to multiple cost inputs
  const costFields = [,
    "#project_questionnaire_cost_component",
  ];
  costFields.forEach((selector) => {
    $(selector).on("blur", function () {
      validateCost($(this));
    });
  });

  const checkboxes = $(
    "input[name='project_questionnaire[potential_audience][]']",
  );

  $("#new_project_questionnaire").on("submit", function (event) {
    let atLeastOneChecked = false;

    checkboxes.each(function () {
      if ($(this).is(":checked")) {
        atLeastOneChecked = true;
        return false; // exit the loop
      }
    });

    if (!atLeastOneChecked) {
      checkboxes
        .first()
        .before(
          '<span class="form-error is-visible" role="alert">musisz wybrać przynajmniej jeden</span>',
        );
      checkboxes.first().parent().prev().addClass("is-invalid-label");
      checkboxes.first().closest(".form-error").hide();

      event.preventDefault(); // prevent form submission
    } else {
      checkboxes.first().parent().prev().removeClass("is-invalid-label");
      checkboxes.first().closest(".form-error").hide();
    }
  });

  $('.document_required_radio').on("change", function (e) {
    if ($('#project_questionnaire_document_required_true').is(':checked')) {
      $('#project_questionnaire_attachments').attr('required', "required");
      $('#project_questionnaire_attachments').addClass('error-border');
    } else {
      $('#project_questionnaire_attachments').prop('required', false);
      $('#project_questionnaire_attachments').removeClass('error-border');
    }
  });

  $('#project_questionnaire_attachments').on('change', function (e) {
    if ($(this).val !== '') {
      $('#attachment_confirmation').attr('checked', 'checked');
    } else {
      $('#attachment_confirmation').prop('checked', false);
    }
  })
});

function validatePESELInProjectForm(pesel, i) {
  if (pesel.length !== 11) {
    return false;
  }

  if (!/^\d{11}$/.test(pesel)) {
    return false;
  }

  const year = parseInt(pesel.substring(0, 2), 10);
  const month = parseInt(pesel.substring(2, 4), 10);
  const day = parseInt(pesel.substring(4, 6), 10);

  let fullYear = 1900 + year;
  if (month > 20) {
    fullYear += 100;
  }
  if (month > 80) {
    fullYear += 200;
  }

  const birth = new Date(fullYear, month - 1, day);
  const today = new Date();

  let age = today.getFullYear() - birth.getFullYear();

  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  if (age >= 18) {
    document.getElementsByClassName('parents-div')[i].style.display = 'none';
    document.getElementsByClassName('parents-confirmation-div')[i].style.display = 'none';
    document.getElementsByClassName('parents-confirmation-check')[i].removeAttribute('required');
  } else {
    document.getElementsByClassName('parents-div')[i].style.display = 'block';
    document.getElementsByClassName('parents-confirmation-div')[i].style.display = 'block';
    document.getElementsByClassName('parents-confirmation-check')[i].setAttribute('required', '');
  }
}

const space = document.querySelector('#content > main > div > section.content-block.participatory-space__content-block > div.space-y-8');

if (space) {
  const links = space.querySelectorAll('a');
  links.forEach(link => {
    link.style.color = 'green';
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const menu = document.querySelector('[aria-label="menu-vertical"]');
  if (menu) {
    menu.innerHTML = `
      <div class="custom-dropdown">
        <button class="custom-dropbtn">Moje Konto</button>
        <div class="custom-dropdown-content">
          <a href="/account">Ustawienia użytkownika</a>
          <a href="/notifications_settings">Ustawienia powiadomień</a>
          <a href="/authorizations">Autoryzacje</a>
          <a href="/user_interests">Moje zainteresowania</a>
          <a href="/download_your_data">Moje dane</a>
          <a href="/account/delete">Usuń moje konto</a>
        </div>
      </div>
      <ul class="vertical-tabs__list">
        <li class="tabs-title"><a href="/profiles/Admin/activity">Mój profil publiczny</a></li>
        <li class="tabs-title"><a href="/project/my">Moje projekty</a></li>
        <li class="tabs-title"><a href="/notifications">Powiadomienia</a></li>
        <li class="tabs-title"><a href="/conversations">Konwersacje</a></li>
        <li class="tabs-title"><a href="/logout">Wyloguj się</a></li>
      </ul>
    `;
  } else {
    console.error('Element o aria-label "menu-vertical" nie istnieje.');
  }
});
